import { Helmet } from "react-helmet";
import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { SiteHeadingContext } from "../types/site-heading-context";

function SiteHead({ pageContext }): JSX.Element {
  const {
    site: {
      siteMetadata: { title: title, titleSeparator: titleSeparator },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          titleSeparator
        }
      }
    }
  `);

  const context: SiteHeadingContext = GetPageContext(pageContext);
  console.log(context);
  return (
    <>
      {
        <Helmet htmlAttributes={{lang: 'en'}}>
          <title>{getPageTitle(title, titleSeparator, context.title)}</title>
          {context.description && (
            <meta name="description" content={context.description}></meta>
          )}
          <meta name="author" content="Austin Farmer" />
          <meta name="copyright" content="Austin Farmer" />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/prism/1.26.0/themes/prism-tomorrow.min.css"
            media="print"
            onLoad="this.media='all'"
          />
        </Helmet>
      }
    </>
  );
}

function getPageTitle(
  title: string,
  titleSeparator: string,
  contentTitle: string | null
): string {
  if (contentTitle === null || contentTitle === undefined) {
    return title;
  }

  return `${contentTitle}${titleSeparator}${title}`;
}

function GetPageContext(pageContext: any): SiteHeadingContext | null {
  return GetPageContextInner(
    pageContext.frontmatter ? pageContext.frontmatter : pageContext
  );
}

function GetPageContextInner(src: any): SiteHeadingContext | null {
  let resultantContext: SiteHeadingContext | null = null;
  try {
    resultantContext = {
      title: src.title,
      description: src.description,
      datePublished: src.date,
      tags: src.tags,
    };
  } finally {
    return resultantContext;
  }
}

export default SiteHead;
