import * as React from "react";
import Branding from "./branding";
import { graphql, useStaticQuery } from "gatsby";
import Link from "../link";

function MainNavigation(): JSX.Element {
  /**
   * @TODO: Investigate .graphql file replacement.
   */
  const {
    site: {
      siteMetadata: { primaryNavigationItems },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          primaryNavigationItems {
            name
            path
          }
        }
      }
    }
  `);

  const menuItems = primaryNavigationItems.map((navigationItem) => {
    return (
      <li className="nav-item mb-3" key={navigationItem.path}>
        <Link
          href={navigationItem.path}
          className="nav-link"
          activeClassName="active"
          partiallyActive={true}
        >
          {navigationItem.name}
        </Link>
      </li>
    );
  });

  return (
    <nav
      aria-label="Primary Navigation"
      className="navbar navbar-expand-md navbar-light navbar-primary p-0"
    >
      <div className="container-fluid flex-md-column">
        <Branding />
        <button
          className="navbar-toggler border-0"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapsable-navbar"
          aria-controls="collapsable-navbar"
          aria-expanded="false"
          aria-label="Toggle collapsable navigation."
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="navbar-collapse collapse align-self-md-start"
          id="collapsable-navbar"
        >
          <ul className="navbar-nav flex-md-column">{menuItems}</ul>
        </div>
      </div>
    </nav>
  );
}

export default MainNavigation;
