import * as React from "react";
import { Link as InternalLink } from "gatsby";

/**
 * This expects that an internal link is one that starts with
 * a forward slash only.
 *
 * In the future, let's explore domain-awareness as well.
 */
const internalLinkRegex: RegExp = /^\/(?!\/)/;

function Link({
  href,
  activeClassName = "",
  partiallyActive = false,
  children,
  ...etc
}): JSX.Element {
  const isInternal: boolean = internalLinkRegex.test(href);
  if (isInternal) {
    return (
      <InternalLink
        to={href}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...etc}
      >
        {children}
      </InternalLink>
    );
  } else
    return (
      <a href={href} target="_blank" rel="noopener" {...etc}>
        {children}
      </a>
    );
}

export default Link;
