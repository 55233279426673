import * as React from "react";
import Link from "../link";
import { graphql, useStaticQuery } from "gatsby";
import AustinImg from "../../images/austin-circle@2x.png";

function Branding(): JSX.Element {
  /**
   * @TODO: Investigate .graphql file replacement.
   */
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(graphql`
        {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

  return (
    <div className="row justify-content-center">
      <Link
        href="/"
        className="navbar-brand d-flex flex-md-column text-uppercase fw-bold text-center mx-0"
      >
        <img
          src={AustinImg}
          className={"w-100 mb-3 d-none d-md-block austin-nav-photo"}
          alt="The myth, the man, the legend."
        />
        <span className="brand-title">{title}</span>
      </Link>
    </div>
  );
}

export default Branding;
