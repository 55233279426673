import * as React from "react";
import { MDXProvider } from "@mdx-js/react";
import MainNavigation from "./navigation/main-navigation";
import "../sass/main.scss";
import Link from "./link";
import SiteHead from "./site-head";

const mdxComponents = {
  a: Link,
};

function Layout({ children, pageContext }): JSX.Element {
  return (
    <div className="container-fluid">
      <SiteHead pageContext={pageContext} />
      <div className="row min-vh-100">
        <div className="col-md-3 col-xxl-2 bg-primary sticky-top">
          <div className="row sticky-top">
            <MainNavigation />
          </div>
        </div>
        <div className="col-md-9 col-xxl-10">
          <div className="row pt-3 pt-md-5">
            <div className="col-md-1"></div>
            <div className="col-md-11 min-vh-100 col-lg-8 mb-5">
              <main className="row">
                <MDXProvider components={mdxComponents}>{children}</MDXProvider>
              </main>
            </div>
            <footer className="col-12 text-center bg-secondary">
              &copy; 2021-{new Date().getFullYear()} Austin Farmer
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Layout;
